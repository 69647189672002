<template>
  <div>
    <!-- start override menu -->
    <vertical-nav-menu
      class="active_project_menu"
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
      :address_info="nav_bar_address"
    >
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </vertical-nav-menu>
    <div v-if="isBusy" id="lottie">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <!-- end override menu -->
    <!-- start page content -->
    <div v-else>
      <!-- <h1>{{ $route.name }}</h1>
      <h2>{{ $route.params }}</h2> -->
      <!-- <custom-table
        v-if="!isBusy"
        :default_fields="fields"
        :data_table="quotes"
        :fields_options="options"
        :busy_table="isBusy"
      /> -->
      <over-view
        :address="nav_bar_address"
        v-if="$route.name == 'Active Project Summary'"
        :signed="opportunity.ccdc_signed"
        :quote="opportunity"
        @updateTfr="updateActiveTfr"
        @openTfrModal="openTfrModal"
        @marksigned="ccdcSigned"
        @revertMarksigned="revertMarkCcdcSigned"
        @openCOCreate="createCOmodal = true"
      />
      <!-- <revision
        :address="nav_bar_address"
        v-if="$route.name == 'Active Project Summary'"
        :signed="opportunity.ccdc_signed"
        :revisionProp="opportunity.active_tfr"
        :attachments_folder_url="opportunity.attachments_folder_url"
        :rentable_sqft="opportunity.rentable_sqft"
        :account_name="opportunity.account_name"
      /> -->
      <construction-view
        :address="nav_bar_address"
        v-else-if="$route.name == 'Active Project Construction'"
        :signed="opportunity.ccdc_signed"
        :quote="activeQuote"
        :change_orders="opportunity.active_change_orders"
      />
      <FFE
        :quote="activeQuote"
        :address="nav_bar_address"
        v-else-if="$route.name == 'Active Project FF&E'"
        :signed="opportunity.ccdc_signed"
        :change_orders="opportunity.active_change_orders"
      />
      <AVIT
        :address="nav_bar_address"
        :quote="activeQuote"
        v-else-if="$route.name == 'Active Project AV / IT'"
        :signed="opportunity.ccdc_signed"
        :change_orders="opportunity.active_change_orders"
      />
      <FinanceView
        v-else
        :address="nav_bar_address"
        :signed="opportunity.ccdc_signed"
        :quote="activeQuote"
        :change_orders="approvedChangeOrders"
        :opp_id="opportunity.sf_opportunity_id"
        :tender_address="opportunity.address"
        :active_tfr="activeTfr"
      />
    </div>
    <!-- end page content -->
    <!-- create tfr modal -->
    <b-modal
      v-model="createmodal"
      scrollable
      size="lg"
      title="Create a New Revision"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      id="tfr-creation"
      ref="tfr-creation"
    >
      <create-new-tfr
        :opp="opportunity"
        @createdTfr="createdTfr"
        @errorModal="errorModal"
        non-leaking-style-scope
      />
    </b-modal>
    <!-- end create tfr modal -->
    <!-- create CO modal -->
    <b-modal
      id="creation"
      ref="creation"
      v-model="createCOmodal"
      title="Create Change Order"
      @ok="handleOk"
      :centered="true"
      body-class="py-2 px-3"
      :no-close-on-backdrop="true"
      size="lg"
      :ok-disabled="modalIsBusy"
      :cancel-disabled="modalIsBusy"
      ok-title="Create Change Order"
      cancel-title="Cancel"
      cancel-variant="outline-secondary"
    >
      <h5>Summary</h5>
      <label style="padding-top: 5px; font-size: 13px">Reasons for Change</label>
      <v-select
        :clearable="false"
        v-model="contractPageForm.type"
        :options="reasonChangeOptions"
        style="font-size: 14px"
      ></v-select>
      <label style="padding-top: 20px; font-size: 13px">Change Order Overview</label>
      <b-form-textarea
        style="font-size: 14px"
        placeholder="Describe what is changing and why."
        :state="contractPageForm.overview.length <= 1000"
        rows="3"
        v-model="contractPageForm.overview"
      />
      <small
        class="textarea-counter-value float-right"
        :class="contractPageForm.overview.length > 1000 ? 'bg-danger' : ''"
      >
        <span class="char-count">{{ contractPageForm.overview.length }}</span> /
        {{ 1000 }}
      </small>
    </b-modal>
    <!-- end CO modal -->
  </div>
</template>

<script>
import { BModal, BFormTextarea } from "bootstrap-vue";
import VerticalNavMenu from "@/@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import useVerticalLayout from "@/@core/layouts/layout-vertical/useVerticalLayout";
import { onUnmounted } from "@vue/composition-api";
import CustomTable from "./Components/CustomTable.vue";
import OverView from "./Components/OverView.vue";
import ConstructionView from "./Components/ConstructionView.vue";
import FFE from "./Components/FFEView.vue";
import AVIT from "./Components/AVITView.vue";
import FinanceView from "./Components/FinanceView.vue";
import Revision from "./Components/Revision.vue";

import vSelect from "vue-select";
// modals
import CreateNewTfr from "./Components/Modals/CreateNewTfr.vue";
export default {
  components: {
    VerticalNavMenu,
    CustomTable,
    OverView,
    ConstructionView,
    FFE,
    AVIT,
    FinanceView,
    Revision,
    CreateNewTfr,
    BModal,
    vSelect,
    BFormTextarea,
  },
  mounted() {},
  data() {
    return {
      opportunityId: this.$route.params.ActiveProjectId,
      opportunity: {
        ccdc_signed: false,
      },
      nav_bar_address: "",
      quotes: [],
      isBusy: true,
      fields: [
        {
          key: "sf_opportunity_id",
          label: "#",
          type: "string",
        },
        {
          key: "address",
          label: "PROJECT",
          type: "string",
        },
        {
          key: "account_name",
          label: "CLIENT",
          type: "string",
        },
        {
          key: "rentable_sqft",
          label: "RENTABLE SQFT",
          type: "float",
        },
        {
          key: "sf_opportunity_status",
          label: "STATUS",
          type: "string",
        },
      ],
      options: [
        {
          label: "#",
          item: { key: "sf_opportunity_id", label: "#", type: "string" },
        },
        {
          label: "PROJECT",
          item: {
            key: "address",
            label: "PROJECT",
            type: "string",
          },
        },
        {
          label: "CLIENT",
          item: {
            key: "account_name",
            label: "CLIENT",
            type: "string",
          },
        },
        {
          label: "RENTABLE SQFT",
          item: {
            key: "rentable_sqft",
            label: "RENTABLE SQFT",
            type: "float",
          },
        },
        {
          label: "STATUS",
          item: {
            key: "sf_opportunity_status",
            label: "STATUS",
            type: "string",
          },
        },
        ,
      ],
      activeQuote: [],
      activeTfr: [],
      createmodal: false,
      createCOmodal: false,
      modalIsBusy: false,
      contractPageForm: {
        overview: "",
        type: "Client Directed Change",
        tfr_id: "",
      },
      items: [
        {
          label: "Client Directed Change",
          value: "Client Directed Change",
        },
        {
          label: "Included",
          value: true,
        },
      ],
      reasonChangeOptions: [
        "Client Directed Change",
        "Unforeseen Site Condition",
        "Design/Eng Error + Omission",
      ],
    };
  },
  beforeMount() {
    this.getActiveProject();
    // this.getQuotes();
  },
  computed: {
    routeName() {
      if (this.opportunity.address) {
        this.updateBreadcrumb();
      } else {
        this.$root.$emit("rerender-breadcrumb");
      }
      return this.$route.name;
    },
    approvedChangeOrders() {
      const results = this.opportunity.active_change_orders.sort(
        (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
      );
      return results;
    },
  },
  watch: {
    routeName(newValue, oldValue) {
      this.updateTitle();
    },
  },
  methods: {
    getActiveProject() {
      this.$http
        .get(`/opportunities/active/${this.opportunityId}`, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.opportunity = response.data;
          this.activeQuote = this.opportunity.active_quote;
          this.activeTfr = this.opportunity.active_tfr;
          this.nav_bar_address = `${this.opportunity.address}, ${this.opportunity.geography}`;
          this.tfr_id = this.opportunity.active_tfr.id;
          // this.updateBreadcrumb();
          if (this.routeRevisionId) {
            const index = this.opportunity.tfrs
              .map((e) => e.tfr)
              .indexOf(this.routeRevisionId);
            this.revision = this.opportunity.tfrs[index];
            this.selectedRequirements = this.revision.requirements;
            this.selectedIndex = index;
          }
          this.isBusy = false;
          this.$root.$emit("update-signed-alert", this.opportunity.ccdc_signed);
          this.$root.$emit("update-completed-alert", this.opportunity.marked_as_complete);
        })
        .catch((error) => {
          this.isBusy = false;
          this.tableIsBusy = false;
          this.showToast("danger", error);
        });
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].to = "/active";
      this.$route.meta.breadcrumb[1].text = `${this.nav_bar_address}`;
      this.$route.meta.breadcrumb[2].text = `Summary`;
      this.$route.meta.breadcrumb[0].active = true;
      this.$route.meta.breadcrumb[1].active = true;
      this.$route.meta.breadcrumb[2].active = true;
      this.$root.$emit("rerender-breadcrumb");
      this.tableIsBusy = false;
      this.updateTitle();
    },
    updateTitle() {
      let routeName = this.$route.name;
      if (routeName == "Active Project Summary") {
        document.title = `${this.opportunity.sf_opportunity_number} - ${this.opportunity.address}`;
      } else if (routeName == "Active Project Construction") {
        document.title = `${this.opportunity.sf_opportunity_number} Construction`;
      } else if (routeName == "Active Project FF&E") {
        document.title = `${this.opportunity.sf_opportunity_number} FF&E`;
      } else if (routeName == "Active Project Finance") {
        document.title = `${this.opportunity.sf_opportunity_number} Finance`;
      } else {
        document.title = `${this.opportunity.sf_opportunity_number} AV / IT`;
      }
    },
    updateActiveTfr(item) {
      this.$http
        .get(
          `/opportunities/${this.opportunityId}/tfr/${item.id}/replace-active-revision`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.showToast(
            "success",
            `Data for this project has been updated.`,
            `Active Revision Updated`
          );
          this.opportunity.tfrs = response.data.data;
          this.getActiveProject();
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.tableIsBusy = false;
          this.showToast("danger", error.response.data.message);
        });
    },
    async createdTfr(tfr) {
      this.getActiveProject();
      this.createmodal = false;
      this.showToast("success", "Revision Created Successfully", "Revision Created");
    },
    errorModal(variable) {
      this.showToast("danger", "Contact Digital for support.", "Something went wrong");
    },
    openTfrModal() {
      this.createmodal = true;
    },
    ccdcSigned() {
      const h = this.$createElement;
      const messageVNode = h("div", { class: ["sign_content"] }, [
        h("p", [
          " Marking the contract as signed will lock all project data. Additional changes will only be able to be made through Change Orders. ",
        ]),
        h("strong", "This action cannot be undone."),
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Mark Contract as Signed",
          hideHeaderClose: true,
          okTitle: "Mark Contract as Signed",
          cancelTitle: "Go Back",
          centered: true,
          bodyClass: "m-1",
          size: "700px",
        })
        .then((value) => {
          if (value == true) {
            this.$http
              .get(`/opportunities/${this.opportunityId}/mark-ccdc-signed`)
              .then((response) => {
                //var activeQuoteRevision = this.opportunity.tfrs.find(n => (n.submitted_for_handover == true));
                this.showToast(
                  "success",
                  "Additional changes to the project must now be made through Change Orders.",
                  "Contract has been signed!"
                );
                this.opportunity.ccdc_signed = true;
                this.$root.$emit("update-signed-alert", this.opportunity.ccdc_signed);
              })
              .catch((error) => {
                console.log(error);
                this.showToast("danger", error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    revertMarkCcdcSigned() {
      this.$http
        .get(`/opportunities/${this.opportunityId}/unmark-ccdc-signed`)
        .then((response) => {
          this.opportunity.ccdc_signed = false;
          this.$root.$emit("update-signed-alert", this.opportunity.ccdc_signed);
        })
        .catch((error) => {
          console.log(error);
          this.showToast("danger", error);
        });
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.submitChangeOrder();
    },
    submitChangeOrder() {
      this.modalIsBusy = true;
      this.$http
        .post(`/opportunities/${this.opportunityId}/change-orders`, this.contractPageForm)
        .then((response) => {
          this.modalIsBusy = false;
          this.showToast(
            "success",
            `Change Order ${response.data.data.change_orders[0].change_order_number} has been created successfully.`,
            "Change Order Created"
          );
          this.createCOmodal = false;
          this.contractPageForm = {
            overview: "",
            type: "Client Directed Change",
            tfr_id: this.opportunity.active_tfr.id,
          };
          this.getActiveProject();
        })
        .catch((error) => {
          this.modalIsBusy = false;
          this.$emit("errorModal");
        });
    },
    // getQuotes() {
    //   this.$http
    //     .get("/opportunities", {
    //       headers: {
    //         Accept: "application/json",
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
    //       this.quotes = response.data;
    //       this.totalRows = this.quotes.length;
    //       //this.totalRows = this.quotes.length;
    //       this.isBusy = false;
    //     })
    //     .catch((error) => {
    //       this.showToast("danger", error.response.data.message);
    //     });
    // },
  },
  setup() {
    const { navbarType, footerType, isNavMenuHidden } = useAppConfig();

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType);

    // Resize handler
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    onUnmounted(() => {
      window.removeEventListener("resize", resizeHandler);
    });

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      isNavMenuHidden,
    };
  },
};
</script>

<style scoped>
[dir] .breadcrumb {
  padding: unset !important;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
}
.active_project_menu {
  left: 0;
  top: 0;
}
.sign_content {
  font-size: 14px;
}
@media screen and (max-width: 1200px) {
  .active_project_menu {
    display: none;
  }
}
</style>
