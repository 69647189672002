<template>
  <b-card>
    <h1>this is a custom filterable table</h1>
    <div class="d-flex justify-content-end my-2">
      <b-dropdown
        style="width: 180px"
        variant="outline-secondary"
        text="Split Link"
        checkbox-menu
        allow-focus
      >
        <template #button-content>
          <span> <uil-columns /> Columns </span>
        </template>
        <b-dropdown-form class="w-100">
          <b-form-checkbox
            v-model="displayColumnsCheck"
            v-for="(f, index) in allfields"
            :key="'check' + index"
            :value="f.key"
            @change="selectColumn(f)"
            >{{ f.label }}
          </b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="data_table"
      styleClass=" table b-table"
      :pagination-options="{ enabled: true, mode: 'records' }"
      :rowStyleClass="'rowStyleClass'"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.type == 'float'">
          <i class="fa fa-address-book"></i>
          {{
            Number(props.formattedRow[props.column.field].toFixed(2)).toLocaleString(
              "en",
              { minimumFractionDigits: 2 }
            )
          }}
        </span>
        <span v-else-if="props.column.type == 'money'">
          <i class="fa fa-address-book"></i>
          {{
            " $" +
            Number(props.formattedRow[props.column.field].toFixed(2)).toLocaleString(
              "en",
              { minimumFractionDigits: 2 }
            )
          }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">No records found</div>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  BPagination,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BInputGroupAppend,
  BDropdown,
  BTooltip,
  BDropdownItem,
  BFormCheckboxGroup,
  BFormCheckbox,
  BDropdownForm,
} from "bootstrap-vue";

import vSelect from "vue-select";
import moment from "moment";
// import the styles
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { UilColumns } from "@iconscout/vue-unicons";
export default {
  props: {
    data_table: {
      type: Array,
      default: [],
    },
    default_fields: {
      type: Array,
      default: [],
    },
    fields_options: {
      type: Array,
      default: [],
    },
    busy_table: {
      type: Boolean,
      default: [],
    },
  },
  components: {
    BCard,
    BCardText,
    BTable,
    BButton,
    BModal,
    BRow,
    BCol,
    BBadge,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BDropdown,
    BTooltip,
    BDropdownItem,
    BFormCheckboxGroup,
    moment,
    VueGoodTable,
    BFormCheckbox,
    BDropdownForm,
    UilColumns,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    let vm = this;
    return {
      selected: this.default_fields,
      quotes: [],
      isBusy: this.busy_table,
      filter: null,
      fields: this.fields_options,
      perPage: 15,
      currentPage: 1,
      totalRows: 1,
      statusOptions: [
        "Identified",
        "Quote Sent",
        "WON",
        "Quote Hardened",
        "Quote Revised",
      ],
      options: [],
      table_data: this.data_table,
      columnsHidden: {},
      isModalFilterVisible: false,
      columns: [],
      hiddenColumns: this.default_fields,
      allfields: this.default_fields,
      displayColumnsCheck: [],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    this.initTable();
  },
  methods: {
    initTable() {
      let v = this;
      v.table_data = v.data_table;
      v.totalRows = v.table_data.length;
      v.columns = [];

      if (v.default_fields.length > 0) {
        v.default_fields.map((f) => {
          v.columns.push({
            label: f.label,
            field: f.key,
            type: f.type,
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter This Thing', // placeholder for filter input
              filterDropdownItems: this.filterDropdownItems(f.key),
              trigger: 'enter', //only trigger on enter not on keyup 
            },
            formatFn(value) {
              return value === null ? "null" : value;
            },
          });
          v.displayColumnsCheck.push(f.key);
        });
      }
      //   v.isBusy = v.busy_table;
    },
    filterDropdownItems(key) {
      let index = "'" + key + "'";
      var values = this.data_table.map( (value) => value[key]).filter( (value, index, _arr) => _arr.indexOf(value) == index);
      return values
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    relativeTime(date) {
      return moment(date).fromNow();
    },
    formatDatetime(date) {
      return moment(date).format("MM-DD-YYYY HH:mm:ss");
    },
    clickRow(record, index) {
      this.clicked(record);
      // console.log(record);
    },
    toggleColumn(column, event) {
      // Set hidden to inverse of what it currently is
      if (this.columnsHidden[column.field] === undefined) {
        this.$set(column, "hidden", !column.hidden);
        this.$set(this.columnsHidden, column.field, column);
      } else {
        this.$set(column, "hidden", !column.hidden);
        this.$delete(this.columnsHidden, column.field);
        //event.currentTarget.remove();
      }
    },

    fieldName(field) {
      let resField = this.default_fields.filter((d) => d.key == field);
      console.log(resField);
      return resField[0].label;
    },
    showModalFilter() {
      this.isModalFilterVisible = true;
    },
    onCloseModalFilter(filters) {
      let dataNew = JSON.parse(JSON.stringify(this.data)),
        incorrectFilter = false;

      dataNew = dataNew.filter((row, index_data, array_dataNew) => {
        if (incorrectFilter) {
          return false;
        }
        return filters.every((filter, index) => {
          try {
            return new Function("row", `return(${filter});`)(row);
          } catch (error) {
            alert(`Błędny filtr:\n${filter}`);
            incorrectFilter = true;
            return false;
          }
        });
      });
      this.isModalFilterVisible = false;
      this.rows = dataNew;
    },
    selectColumn(el) {
      let v = this;
      if (v.columns.length > 0) {
        v.columns.map((c) => {
          if (el.key == c.field) {
            this.toggleColumn(c);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.identified {
  background-color: #0d6efd1f;
  color: #0d6efd;
}

.quotesent {
  background-color: #6610f21f;
  color: #6610f2;
}

.quoterevised {
  background-color: #7367f01f;
  color: #7367f0;
}

.quotehardened {
  background-color: #d633841f;
  color: #d63384;
}

.approved {
  background-color: #00cfe81f;
  color: #00cfe8;
}

.won {
  background-color: #28c76f1f;
  color: #28c76f;
}

.lost {
  background-color: #6c757d1f;
  color: #4b4b4b;
}

.onhold {
  background-color: #ff9f431f;
  color: #ff9f43;
}

.invoice-filter-select {
  min-width: 190px;
}
.vgt-wrap__footer {
  padding: 1em;
  border: 0px solid #dcdfe6 !important;
  background: transparent !important;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
