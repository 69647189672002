<template>
  <div>
    <div>
      <h1 class="mb-1" style="margin-top: 10px"><b>AV / IT</b></h1>
      <p v-if="signed">AV / IT breakdown for all approved project quotes.</p>
      <b-card>
        <b-row>
          <b-col cols="4">
            <div class="my-1 mx-1">
              <p class="card_title">Total AV/IT Price</p>
              <h3>
                {{
                  Number(avitTableTotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>

          <b-col v-if="Number(quote.base_spec_version) >= 3" cols="4" class="d-flex">
            <hr class="title_divider" />
            <div class="my-1 mx-1">
              <p class="card_title">Total AV Price</p>
              <h3>
                {{
                  Number(avPriceTotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>
          <b-col v-else cols="4" class="d-flex">
            <hr class="title_divider" />
            <div class="my-1 mx-1">
              <p class="card_title">Total Delivery & Install</p>
              <h3>
                {{
                  Number(avitDelivertTotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>
          <b-col v-if="Number(quote.base_spec_version) >= 3" cols="4" class="d-flex">
            <hr class="title_divider" />
            <div class="my-1 mx-1">
              <p class="card_title">Total IT COGS + D&I</p>
              <h3>
                {{
                  Number(itCostTotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- start base spec table -->
      <b-card>
        <b-row
          v-if="
            Number(quote.base_spec_version) < 3.3
              ? quote.avit_toggle
              : quote.av_toggle || quote.it_toggle
          "
        >
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363"><b>Base Spec</b></h3>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  class="my-1 mr-2"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="my-1 pl-0" align-self="end" style="text-align: end">
                <div
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    class="w-100"
                    variant="outline-secondary"
                    checkbox-menu
                    allow-focus
                    dropup
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ baseSpecSelectedAll }}
                          <b-form-checkbox
                            class="pb-0 mb-0"
                            v-model="allSelected"
                            @change="AllDrop"
                          >
                            {{ allSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                        </template>
                        <hr class="p-0 m-0 mb-1" />
                        <b-form-checkbox
                          v-model="displayColumnsCheck"
                          v-for="(f, index) in fields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox>
                      </b-form-group>
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          @click="resetCols('base')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="avitItems.length > 0"
              :headers="headers"
              :items="avitItems"
              item-key="id"
              ref="myTable"
              @current-items="currentItems"
              @click:row="clicked"
              :search="search"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <template v-slot:header.supply_price_per_unit="{ header }">
                {{ header.text }} <uil-info-circle id="supply_price_per_unit" />

                <b-tooltip
                  title="COGS / Unit + Markup / Unit"
                  :target="'supply_price_per_unit'"
                />
              </template>
              <template v-slot:header.unit_price="{ header }">
                {{ header.text }} <uil-info-circle id="unit_price" />
                <b-tooltip
                  title="Price / Unit + Delivery & install / Unit"
                  :target="'unit_price'"
                />
              </template>
              <template v-slot:header.total_price="{ header }">
                {{ header.text }} <uil-info-circle id="total_price" />
                <b-tooltip
                  title="(Price / Unit + Delivery & install / Unit) x Quantity"
                  :target="'total_price'"
                />
              </template>
              <template v-slot:header.total_supply_price="{ header }">
                {{ header.text }} <uil-info-circle id="total_supply_price" />
                <b-tooltip
                  title="(CS Unit Cost + Markup / Unit) x Quantity"
                  :target="'total_supply_price'"
                />
              </template>
              <template v-slot:header.unit_cost="{ header }">
                {{ header.text }} <uil-info-circle id="unit_cost" />
                <b-tooltip title="Cost of Goods Sold" :target="'unit_cost'" />
              </template>
              <template v-slot:header.total_cost="{ header }">
                {{ header.text }} <uil-info-circle id="total_cost" />
                <b-tooltip title="Cost of Goods Sold x Quantity" :target="'total_cost'" />
              </template>
              <template v-slot:header.cost_per_sqft="{ header }">
                {{ header.text }} <uil-info-circle id="cost_per_sqft" />
                <b-tooltip
                  title="(Cost of Goods Sold x Quantity) / SQFT"
                  :target="'cost_per_sqft'"
                />
              </template>
              <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
                <div class="d-flex">
                  <div style="display: inline-block; padding: 16px 0" :key="i">
                    {{ header.text }}
                  </div>
                  <div
                    style="float: right; margin-top: 8px"
                    id="attachId"
                    :key="'filter' + i"
                  >
                    <v-menu
                      :close-on-content-click="false"
                      :max-width="400"
                      :min-width="300"
                      bottom
                      right
                      attach="#attachId"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon
                            small
                            :style="
                              activeFilters[header.value] &&
                              activeFilters[header.value].length <
                                filters[header.value].length
                                ? 'color:black'
                                : 'color:#dedede'
                            "
                          >
                            mdi-filter-variant
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item-group>
                          <template v-for="item in filters[header.value]">
                            <v-list-item :key="`${item}`" :value="item" :ripple="false">
                              <template>
                                <v-list-item-action>
                                  <b-form-checkbox
                                    v-model="activeFilters[header.value]"
                                    :value="item"
                                  ></b-form-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  v-if="header.value=='uniformat'"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                                <v-list-item-title v-else
                                  style="font-weight: normal; text-transform: none"
                                  >{{ item }} </v-list-item-title
                                >
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                      <v-row no-gutters style="background-color: #fff">
                        <v-col cols="6">
                          <b-button
                            block
                            @click="toggleAll(header.value)"
                            variant="outline-light"
                            style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                            >Select all</b-button
                          >
                        </v-col>
                        <v-col cols="6">
                          <b-button
                            block
                            @click="clearAll(header.value)"
                            variant="outline-light"
                            style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                            >Deselect all</b-button
                          >
                        </v-col>
                      </v-row>
                    </v-menu>
                  </div>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.mark_up_per_unit="{ item }">
                {{
                  Number(
                    markup(item.supply_price, item.unit_cost).toFixed(2)
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_markup="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(
                      (markup(item.supply_price, item.unit_cost) * item.qty).toFixed(2)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(0).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_price * 1
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>

              <template v-slot:item.cost_per_sqft="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.cost_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      (item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1) /
                        quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                <div v-if="!item.specification_swap">
                  {{
                    Number(item.total_delivery_install).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else>
                  {{
                    Number(
                      Number(item.specification_swap.total_delivery_install)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_price="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.unit_delivery_install="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.unit_delivery_install.toFixed(2)).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_delivery_install / item.qty
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>

              <template v-slot:item.unit_cost="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.unit_cost.toFixed(2)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      (item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1) /
                        item.qty
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.unit_price="{ item }">
                {{
                  Number(item.unit_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_price_sqft="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number((item.total_price / quote.sqft).toFixed(2)).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      (item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1) /
                        quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_supply_price="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number((item.supply_price * item.qty).toFixed(2)).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(item.specification_swap.total_price * 1).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </div>
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>

              <template v-slot:item.supply_price_per_unit="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.supply_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      (item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1) /
                        item.qty
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.element="{ item }">
                <div v-if="!item.specification_swap">
                  {{ item.element.specification_key }}
                </div>
                <div v-else class="d-flex">
                  <div style="width: 150px">
                    {{ item.specification_swap.name }}
                  </div>
                </div>
              </template>
              <template v-slot:item.internal_note="{ item }">
                <div v-if="!item.specification_swap"></div>
                <div v-else class="d-flex">
                  <div style="width: 150px">
                    {{ item.specification_swap.internal_note }}
                  </div>
                </div>
              </template>
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="totalColumnShow('element')"></th>
                  <th v-if="totalColumnShow('vendor')"></th>
                  <th v-if="totalColumnShow('internal_note')"></th>
                  <th v-if="totalColumnShow('subcategory')"></th>
                  <th v-if="totalColumnShow('uniformat')"></th>
                  <th v-if="totalColumnShow('unit_cost')"></th>
                  <th v-if="totalColumnShow('mark_up_per_unit')"></th>
                  <th v-if="totalColumnShow('supply_price_per_unit')"></th>
                  <th v-if="totalColumnShow('unit_delivery_install')"></th>
                  <th v-if="totalColumnShow('unit_price')"></th>
                  <th v-if="totalColumnShow('qty')">
                    {{ Number(sumField("qty")) }}
                  </th>
                  <th v-if="totalColumnShow('total_cost')">
                    {{
                      Number(sumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('cost_per_sqft')">
                    {{
                      Number(sumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_markup')">
                    {{
                      Number(sumMarkUp.toFixed(2)).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_supply_price')">
                    {{
                      Number(sumField("total_supply_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_delivery_install')">
                    {{
                      Number(sumField("total_delivery_install")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_price')">
                    {{
                      Number(sumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_price_sqft')">
                    {{
                      Number(sumField("total_price_sqft").toFixed(2)).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional base spec items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional base spec items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional base spec items to show for this <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <div v-else>
          <h3 class="mt-2" style="color: #636363"><b>Base Spec</b></h3>
          <div class="text-center my-2">
            <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
            <p>
              There are no additional base spec items to show for this <br />
              budget group.
            </p>
          </div>
        </div>
      </b-card>
      <!-- end base spec table  -->
      <!-- ? start AS&U  table-->
      <b-card>
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b v-if="Number(quote.base_spec_version) >= 3"
                    >Base Contract Additions</b
                  >
                  <b v-else> Additional Scope & Upgrades</b>
                </h3>
                <p class="text-muted" v-if="Number(quote.base_spec_version) >= 3">
                  Base Contract Additions are the prices for materials and work above that
                  included in the Base Contract and Construction Specifications. These
                  prices are eligible for a fixed price contact when executed with a CCDC2
                  or CCDC14.
                </p>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-if="ASUItems.length > 0"
                  v-model="ASUsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  style="margin-top: 15px"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="my-1 pl-0" style="margin-top: 15px">
                <div
                  v-if="ASUItems.length > 0"
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    class="w-100"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ ASUSelectedAll }}
                          <b-form-checkbox
                            v-model="ASUallSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="ASUtoggleAllDrop"
                          >
                            {{ ASUallSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                          <b-dropdown-divider class="my-0 py-0"
                        /></template>

                        <b-form-checkbox
                          v-model="ASUdisplayColumnsCheck"
                          v-for="(f, index) in ASUfields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox></b-form-group
                      >
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          @click="resetCols('asu')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="ASUItems.length > 0"
              :headers="ASUheaders"
              :items="ASUItems"
              item-key="id"
              ref="myTable"
              @current-items="ASUcurrentItems"
              @click:row="clicked"
              :search="ASUsearch"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <template
                v-for="(col, i) in ASUfilters"
                v-slot:[`header.${i}`]="{ header }"
              >
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="avASUattachId"
                  :key="'filter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#avASUattachId"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            ASUactiveFilters[header.value] &&
                            ASUactiveFilters[header.value].length <
                            ASUfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in ASUfilters[header.value]">
                          <v-list-item :key="`${item}`" :value="item" :ripple="false">
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="ASUactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="ASUtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          >Toggle all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="ASUclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          >Clear all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.name="{ item }">
                <div class="mt-1">
                  <p>
                    {{ item.name }} <br /><small style="color: #b9b9c3">{{
                      item.details
                    }}</small>
                  </p>
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                {{
                  Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>

              <template v-slot:item.cost_per_sqft="{ item }">
                {{
                  Number(item.cost_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                {{
                  Number(item.total_delivery_install.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_price="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.price_per_sqft="{ item }">
                {{
                  Number(item.price_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="ASUtotalColumnShow('item')"></th>
                  <th v-if="ASUtotalColumnShow('internal_note')"></th>
                  <th v-if="ASUtotalColumnShow('uniformat')"></th>
                  <th v-if="ASUtotalColumnShow('total_cost')">
                    {{
                      Number(ASUsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="ASUtotalColumnShow('cost_per_sqft')">
                    {{
                      Number(ASUsumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="ASUtotalColumnShow('total_price')">
                    {{
                      Number(ASUsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="ASUtotalColumnShow('price_per_sqft')">
                    {{
                      Number(ASUsumField("price_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional additional scope & upgrades items to show for
                  this <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- ? end AS&U table -->
      <!-- ? start CA  table-->
      <b-card v-if="CAItems.length > 0">
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b>Cash Allowances</b>
                </h3>
                <p class="text-muted">
                  A cash allowance is an estimate for materials or work that is required,
                  but which cannot be adequately scoped or priced without further details.
                  When the materials or work can be accurately priced, per the CCDC, the
                  Contract Price shall be adjusted by Change Order to provide for any
                  difference between the cash allowance and the actual cost of the work
                  performed.
                </p>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-if="CAItems.length > 0"
                  v-model="CAsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  style="margin-top: 15px"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="mb-3 pl-0" style="margin-top: 15px">
                <div
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    v-if="CAItems.length > 0"
                    class="w-100"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ CASelectedAll }}
                          <b-form-checkbox
                            v-model="CAallSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="CAtoggleAllDrop"
                          >
                            {{ CAallSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                          <b-dropdown-divider />
                        </template>
                        <b-form-checkbox
                          v-model="CAdisplayColumnsCheck"
                          v-for="(f, index) in CAfields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox>
                      </b-form-group>
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          @click="resetCols('cash')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="CAItems.length > 0"
              :headers="CAheaders"
              :items="CAItems"
              item-key="id"
              ref="myTable"
              @current-items="CAcurrentItems"
              @click:row="clicked"
              :search="CAsearch"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <template v-for="(col, i) in CAfilters" v-slot:[`header.${i}`]="{ header }">
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="avCAattachId"
                  :key="'filter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#avCAattachId"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            CAactiveFilters[header.value] &&
                            CAactiveFilters[header.value].length <
                              CAfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in CAfilters[header.value]">
                          <v-list-item :key="`${item}`" :value="item" :ripple="false">
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="CAactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="CAtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          >Toggle all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="CAclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          >Clear all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.name="{ item }">
                <div class="mt-1">
                  <p>
                    {{ item.name }} <br /><small style="color: #b9b9c3">{{
                      item.details
                    }}</small>
                  </p>
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                {{
                  Number(Number(item.total_cost).toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>

              <template v-slot:item.cost_per_sqft="{ item }">
                {{
                  Number(item.cost_per_sqft).toFixed(2).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                <div v-if="!item.specification_swap">
                  {{
                    Number(item.total_delivery_install).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else>
                  {{
                    Number(
                      Number(item.specification_swap.total_delivery_install)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_price="{ item }">
                {{
                  Number(item.total_price).toFixed(2).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.price_per_sqft="{ item }">
                {{
                  Number(item.price_per_sqft).toFixed(2).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>              
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="CAtotalColumnShow('item')"></th>
                  <th v-if="CAtotalColumnShow('internal_note')"></th>
                  <th v-if="CAtotalColumnShow('uniformat')"></th>
                  <th v-if="CAtotalColumnShow('quantity')"></th>
                  <th v-if="CAtotalColumnShow('cost_per_sqft')">
                    {{
                      Number(CAsumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="CAtotalColumnShow('total_cost')">
                    {{
                      Number(CAsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="CAtotalColumnShow('price_per_sqft')">
                    {{
                      Number(CAsumField("price_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="CAtotalColumnShow('total_price')">
                    {{
                      Number(CAsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional items to show for this <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- ? end CA table -->
      <!-- start change order table card -->
      <b-card v-if="signed">
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b>Change Orders Items</b>
                </h3>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-if="COItems.length > 0"
                  v-model="COsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  class="my-1 mr-2"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="my-1 pl-0" align-self="end" style="text-align: end">
                <div
                  v-if="COItems.length > 0"
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    class="w-100"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ COSelectedAll }}
                          <b-form-checkbox
                            v-model="COallSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="COtoggleAllDrop"
                          >
                            {{ COallSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                          <b-dropdown-divider class="my-0 py-0" />
                        </template>
                        <b-form-checkbox
                          v-model="COdisplayColumnsCheck"
                          v-for="(f, index) in COfields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox>
                      </b-form-group>
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          @click="resetCols('co')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="COItems.length > 0"
              :headers="COheaders"
              :items="COItems"
              item-key="id"
              ref="myTable"
              @current-items="COcurrentItems"
              @click:row="clicked"
              :search="COsearch"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <template v-for="(col, i) in COfilters" v-slot:[`header.${i}`]="{ header }">
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="avCOattachId"
                  :key="'filter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#avCOattachId"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            COactiveFilters[header.value] &&
                            COactiveFilters[header.value].length <
                            COfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in COfilters[header.value]">
                          <v-list-item :key="`${item}`" :value="item" :ripple="false">
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="COactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters  style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="COtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          >Toggle all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="COclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          >Clear all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.name="{ item }">
                <div class="mt-1">
                  <p>
                    {{ item.name }} <br /><small style="color: #b9b9c3">{{
                      item.client_description
                    }}</small>
                  </p>
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>

              <!--   -->
              <template v-slot:item.unit_cost="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.unit_cost.toFixed(2)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      (item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1) /
                        item.qty
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_price="{ item }">
                {{
                  Number(item.total_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.unit_price="{ item }">
                {{
                  Number(item.unit_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.quantity="{ item }">
                {{ Number(item.quantity) }}
              </template>
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="COtotalColumnShow('internal_note')"></th>
                  <th v-if="COtotalColumnShow('uniformat')"></th>
                  <th v-if="COtotalColumnShow('quantity')">
                    {{ Number(COsumField("quantity")) }}
                  </th>
                  <th v-if="COtotalColumnShow('unit_cost')">
                    {{
                      Number(COsumField("unit_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="COtotalColumnShow('total_cost')">
                    {{
                      Number(COsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="COtotalColumnShow('unit_price')">
                    {{
                      Number(COsumField("unit_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="COtotalColumnShow('total_price')">
                    {{
                      Number(COsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no change orders items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no change orders items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no change orders items to show for this <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- end change order table card -->
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BFormInput,
  BFormCheckbox,
  BDropdownForm,
  BDropdown,
  BButton,
  BFormGroup,
  BDropdownDivider,
  BTooltip,
} from "bootstrap-vue";
import {
  UilExclamationTriangle,
  UilColumns,
  UilArrowGrowth,
  UilInfoCircle,
} from "@iconscout/vue-unicons";
import Vuetify, {
  VApp,
  VDataTable,
  VIcon,
  VCol,
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VDivider,
  VListItemGroup,
  VRow,
  VCheckbox,
} from "vuetify/lib";
import uniformat from "@/assets/objects/uniformat-codes.json";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    UilExclamationTriangle,
    BAlert,
    VDataTable,
    VMenu,
    VIcon,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VDivider,
    VListItemGroup,
    VRow,
    VCheckbox,
    BFormInput,
    BFormCheckbox,
    BDropdownForm,
    BDropdown,
    UilColumns,
    BDropdown,
    BButton,
    BFormGroup,
    BDropdownDivider,
    BTooltip,
    UilInfoCircle,
    UilArrowGrowth,
  },
  props: {
    address: {
      type: String,
    },
    quote: {
      type: Object,
    },
    signed: {
      type: Boolean,
    },
    change_orders: {
      type: Array,
    },
  },
  data() {
    return {
      // * uniformat code object
      uniformat: uniformat,
      // *selectAllVars
      allSelected: false,
      ASUallSelected: false,
      COallSelected: false,
      // AVIT dtable vars
      avitItems: [],
      search: "",
      filters: {
        vendor: [],
        subcategory: [],
        uniformat: [],
      },
      filteredItems: [],
      activeFilters: {},
      fields: [
        {
          label: "Specification",
          key: "element",
        },
        {
          label: "Manufacturer",
          key: "vendor",
        },
        {
          label: "Internal Note",
          key: "internal_note",
        },
        {
          label: "Subgroup",
          key: "subcategory",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "COGS / Unit",
          key: "unit_cost",
        },
        {
          label: "Markup / Unit",
          key: "mark_up_per_unit",
        },
        {
          label: "Price / Unit",
          key: "supply_price_per_unit",
        },
        {
          label: "Delivery & Install / Unit",
          key: "unit_delivery_install",
        },
        {
          label: "Price/Unit (W /D&I)",
          key: "unit_price",
        },
        {
          label: "Manufacturer",
          key: "vendor",
        },
        {
          label: "Quantity",
          key: "qty",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Total COGS/sqft",
          key: "cost_per_sqft",
        },
        {
          label: "Total Markup",
          key: "total_markup",
        },
        {
          label: "Total Price",
          key: "total_supply_price",
          sortable: false,
        },
        {
          label: "Total Delivery & Install",
          key: "total_delivery_install",
        },
        {
          label: "Total Price (w/ D&I)",
          key: "total_price",
        },
        {
          label: "Total Price/sqft (w/ D&I)",
          key: "total_price_sqft",
        },
      ],
      displayColumnsCheck: [
        "name",
        "vendor",
        "element",
        "subcategory",
        // "unit_cost",
        // "supply_price_per_unit",
        "qty",
        "total_cost",
        "total_supply_price",
      ],
      totalRows: 0,
      // start AS&U variables
      ASUItems: [],
      ASUsearch: "",
      ASUfilters: {
        uniformat: [],
      },
      ASUfilteredItems: [],
      ASUactiveFilters: {},
      ASUfields: [
        {
          label: "Internal Note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Total COGS/sqft",
          key: "cost_per_sqft",
        },
        {
          label: "Total Price (w/ D&I)",
          key: "total_price",
        },
        {
          label: "Total Price/sqft (w/ D&I)",
          key: "price_per_sqft",
        },
      ],
      ASUdisplayColumnsCheck: [
        "name",
        "internal_note",
        "unit_cost",
        "total_cost",
        "cost_per_sqft",
      ],
      ASUtotalRows: 0,
      // start CO variables
      COItems: [],
      COsearch: "",
      COfilters: {
        uniformat: [],
      },
      COfilteredItems: [],
      COactiveFilters: {},
      COfields: [
        {
          label: "Internal Note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "Quantity",
          key: "quantity",
        },
        {
          label: "COGS / Unit",
          key: "unit_cost",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Price/Unit (W /D&I)",
          key: "unit_price",
        },
        {
          label: "Total Price (w/ D&I)",
          key: "total_price",
        },
      ],
      COdisplayColumnsCheck: ["name", "internal_note", "quantity", "total_cost"],
      COtotalRows: 0,
      // start CA variables
      CAItems: [],
      CAsearch: "",
      CAfilters: {
        uniformat: [],
      },
      CAfilteredItems: [],
      CAactiveFilters: {},
      CAfields: [
        {
          label: "Internal Note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "COGS / SQFT",
          key: "cost_per_sqft",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Price / SQFT",
          key: "price_per_sqft",
        },
        {
          label: "Total Price (w/ D&I)",
          key: "total_price",
        },
      ],
      CAdisplayColumnsCheck: ["name", "internal_note", "total_cost", "total_price"],
      CAtotalRows: 0,
    };
  },

  mounted() {
    this.updateBreadcrumb();
    this.initFilters();
  },
  computed: {
    // * check box computed
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length <= 10) {
        this.allSelected = false;
      } else {
        this.allSelected = true;
      }
    },
    ASUSelectedAll() {
      if (this.ASUdisplayColumnsCheck.length < 6) {
        this.ASUallSelected = false;
      } else {
        this.ASUallSelected = true;
      }
    },
    COSelectedAll() {
      if (this.COdisplayColumnsCheck.length <= 6) {
        this.COallSelected = false;
      } else {
        this.COallSelected = true;
      }
    },
    CASelectedAll() {
      if (this.CAdisplayColumnsCheck.length <= 6) {
        this.CAallSelected = false;
      } else {
        this.CAallSelected = true;
      }
    },
    // *end checkbox computed
    avitTableTotal() {
      let asuPrice = 0;
      let coPrice = 0;
      let caPrice = 0;
      let results = 0;
      if (this.quote.base_spec_version < 3.3) {
        results = this.quote.avit_spec_quote.reduce(
          (total, obj) =>
            (!obj.specification_swap
              ? obj.supply_price * obj.qty
              : obj.specification_swap.total_price * 1) + parseFloat(total),
          0
        );
      } else if (this.quote.av_toggle && this.quote.it_toggle) {
        results = this.quote.avit_spec_quote.reduce(
          (total, obj) =>
            (!obj.specification_swap
              ? obj.supply_price * obj.qty
              : obj.specification_swap.total_price * 1) + parseFloat(total),
          0
        );
      } else if (this.quote.av_toggle) {
        results = this.quote.avit_spec_quote
          .filter((av) => av.subcategory == "AV")
          .reduce(
            (total, obj) =>
              (!obj.specification_swap
                ? obj.supply_price * obj.qty
                : obj.specification_swap.total_price * 1) + parseFloat(total),
            0
          );
      } else if (this.quote.it_toggle) {
        results = this.quote.avit_spec_quote
          .filter((av) => av.subcategory == "IT")
          .reduce(
            (total, obj) =>
              (!obj.specification_swap
                ? obj.supply_price * obj.qty
                : obj.specification_swap.total_price * 1) + parseFloat(total),
            0
          );
      }
      if (this.ASUItems.length > 0) {
        asuPrice = this.ASUItems.reduce((total, obj) => obj.total_price + total, 0);
      }
      if (this.signed) {
        this.change_orders.map(
          (item) =>
            (coPrice =
              coPrice +
              item.change_order_lines
                .filter((x) => x.budget_group == "AV/IT")
                .reduce((total, obj) => obj.total_price + total, 0))
        );
      }
      if (this.COItems.length > 0 && this.signed) {
        coPrice = this.COItems.reduce((total, obj) => obj.total_price + total, 0);
      }
      if (
        Number(this.quote.base_spec_version) < 3.3
          ? !this.quote.avit_toggle
          : !this.quote.av_toggle && !this.quote.it_toggle
      ) {
        results = 0;
      }
      caPrice = this.quote.allowances
        .filter((o) => o.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return results + asuPrice + coPrice + caPrice;
    },
    avitDelivertTotal() {
      let results = this.quote.avit_spec_quote.reduce(
        (total, obj) =>
          (!obj.specification_swap
            ? obj.unit_delivery_install * obj.qty
            : obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      if (
        Number(this.quote.base_spec_version) < 3.3
          ? !this.quote.avit_toggle
          : !this.quote.av_toggle && !this.quote.it_toggle
      ) {
        results = 0;
      }
      return results;
    },
    avPriceTotal() {
      let asuPrice = 0;
      let coPrice = 0;
      let caPrice = 0;
      let results=0

      if (this.quote.base_spec_version < 3.3) {
        results = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == "AV")
        .reduce(
          (total, obj) =>
            parseFloat(
              !obj.specification_swap
                ? obj.supply_price * obj.qty
                : obj.specification_swap.total_price * 1
            ) + parseFloat(total),
          0
        );
      } else if (this.quote.av_toggle && this.quote.it_toggle) {
        results = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == "AV")
        .reduce(
          (total, obj) =>
            parseFloat(
              !obj.specification_swap
                ? obj.supply_price * obj.qty
                : obj.specification_swap.total_price * 1
            ) + parseFloat(total),
          0
        );
      } else if (this.quote.av_toggle) {
        results = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == "AV")
        .reduce(
          (total, obj) =>
            parseFloat(
              !obj.specification_swap
                ? obj.supply_price * obj.qty
                : obj.specification_swap.total_price * 1
            ) + parseFloat(total),
          0
        );
      } else if (this.quote.it_toggle) {
        results = 0
      }
      
      // if (this.ASUItems.length > 0) {
      //   asuPrice = this.ASUItems.reduce((total, obj) => obj.total_price + total, 0);
      // }
      if (this.COItems.length > 0 && this.signed) {
        coPrice = this.COItems.reduce((total, obj) => obj.total_price + total, 0);
      }
      if (
        Number(this.quote.base_spec_version) < 3.3
          ? !this.quote.avit_toggle
          : !this.quote.av_toggle && !this.quote.it_toggle
      ) {
        results = 0;
      }
      // caPrice = this.quote.allowances
      //   .filter((o) => o.budget_group == "AV/IT")
      //   .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return results + asuPrice + coPrice + caPrice;
    },
    itCostTotal() {
      let asuPrice = 0;
      let coPrice = 0;
      let caPrice = 0;
      let results = 0;
      if (this.quote.base_spec_version < 3.3) {
        results = this.quote.avit_spec_quote
          .filter((x) => x.subcategory == "IT")
          .reduce(
            (total, obj) =>
              (!obj.specification_swap
                ? parseFloat(obj.total_cost) + parseFloat(obj.total_delivery_install)
                : obj.specification_swap.total_price * 1 +
                  obj.specification_swap.total_delivery_install * 1) + parseFloat(total),
            0
          );
      } else if (this.quote.av_toggle && this.quote.it_toggle) {
        results = this.quote.avit_spec_quote
          .filter((x) => x.subcategory == "IT")
          .reduce(
            (total, obj) =>
              (!obj.specification_swap
                ? parseFloat(obj.total_cost) + parseFloat(obj.total_delivery_install)
                : obj.specification_swap.total_price * 1 +
                  obj.specification_swap.total_delivery_install * 1) + parseFloat(total),
            0
          );
      } else if (this.quote.av_toggle) {
        results = 0;
      } else if (this.quote.it_toggle) {
        results = this.quote.avit_spec_quote
          .filter((x) => x.subcategory == "IT")
          .reduce(
            (total, obj) =>
              (!obj.specification_swap
                ? parseFloat(obj.total_cost) + parseFloat(obj.total_delivery_install)
                : obj.specification_swap.total_price * 1 +
                  obj.specification_swap.total_delivery_install * 1) + parseFloat(total),
            0
          );
      }

      // if (this.ASUItems.length > 0) {
      //   asuPrice = this.ASUItems.reduce((total, obj) => obj.total_cost + total, 0);
      // }
      if (this.COItems.length > 0 && this.signed) {
        coPrice = this.COItems.reduce((total, obj) => obj.total_cost + total, 0);
      }
      if (
        Number(this.quote.base_spec_version) < 3.3
          ? !this.quote.avit_toggle
          : !this.quote.av_toggle && !this.quote.it_toggle
      ) {
        results = 0;
      }
      // caPrice = this.quote.allowances
      //   .filter((o) => o.budget_group == "AV/IT")
      //   .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return results + asuPrice + coPrice + caPrice;
    },
    sumMarkUp() {
      return this.avitItems.reduce(
        (a, b) =>
          a +
            (!b.specification_swap
              ? this.markup(b.supply_price, b.unit_cost) * b.qty
              : 0) || 0,
        0
      );
    },
    sumTotalSupplyPrice() {
      return this.filteredItems.reduce((a, b) => a + (b.supply_price * b.qty || 0), 0);
    },
    headers() {
      return [
        {
          text: "Element",
          value: "name",
        },
        {
          text: "Specification",
          value: "element",
        },
        {
          text: "Manufacturer",
          value: "vendor",
          sortable: false,
          filter: (value) => {
            return this.activeFilters.vendor
              ? this.activeFilters.vendor.includes(value)
              : false;
          },
        },
        {
          text: "Internal Note",
          value: "internal_note",
        },
        {
          text: "Subgroup",
          value: "subcategory",
          sortable: false,
          filter: (value) => {
            return this.activeFilters.subcategory
              ? this.activeFilters.subcategory.includes(value)
              : false;
          },
        },
        {
          text: "Tender Code",
          value: "uniformat",
          width: "30%",
          sortable: false,
          filter: (value) => {
            return this.activeFilters.uniformat
              ? this.activeFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "COGS / Unit",
          value: "unit_cost",
        },
        {
          text: "Markup / Unit",
          value: "mark_up_per_unit",
        },
        {
          text: "Price / Unit",
          value: "supply_price_per_unit",
          sortable: false,
        },
        {
          text: "Delivery & Install / Unit",
          value: "unit_delivery_install",
        },
        {
          text: "Price/Unit (W /D&I)",
          value: "unit_price",
        },
        {
          text: "Quantity",
          value: "qty",
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Total COGS/SQFT",
          value: "cost_per_sqft",
        },
        {
          text: "Total Markup",
          value: "total_markup",
        },
        {
          text: "Total Price",
          value: "total_supply_price",
        },
        {
          text: "Total Delivery & Install",
          value: "total_delivery_install",
        },
        // {
        //   text: "Total Markup",
        //   value: "total_markup",
        // },
        {
          text: "Total Price (w/ D&I)",
          value: "total_price",
        },
        {
          text: "Total Price/sqft (w/ D&I)",
          value: "total_price_sqft",
        },
      ].filter((s) => this.displayColumnsCheck.includes(s.value));
    },
    // ASU headers
    ASUheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },
        {
          text: "Internal note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          width: "30%",
          sortable: false,
          filter: (value) => {
            return this.ASUactiveFilters.uniformat
              ? this.ASUactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Total COGS/sqft",
          value: "cost_per_sqft",
        },
        {
          text: "Total Price (w/ D&I)",
          value: "total_price",
        },
        {
          text: "Total Price/sqft (w/ D&I)",
          value: "price_per_sqft",
        },
      ].filter((s) => this.ASUdisplayColumnsCheck.includes(s.value));
    },
    // CO headers
    COheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },
        {
          text: "Internal note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          width: "30%",
          sortable: false,
          filter: (value) => {
            return this.COactiveFilters.uniformat
              ? this.COactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "COGS / Unit",
          value: "unit_cost",
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Price/Unit (W /D&I)",
          value: "unit_price",
        },
        {
          text: "Total Price (w/ D&I)",
          value: "total_price",
        },
      ].filter((s) => this.COdisplayColumnsCheck.includes(s.value));
    },
    // CA headers
    CAheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },
        {
          text: "Internal note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          width: "30%",
          sortable: false,
          filter: (value) => {
            return this.CAactiveFilters.uniformat
              ? this.CAactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "COGS / SQFT",
          value: "cost_per_sqft",
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Price / SQFT",
          value: "price_per_sqft",
        },
        {
          text: "Total Price (w/ D&I)",
          value: "total_price",
        },
      ].filter((s) => this.CAdisplayColumnsCheck.includes(s.value));
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    desserts(val) {
      this.initFilters();
    },
  },
  methods: {
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[1].text = `${this.address}`;
      this.$route.meta.breadcrumb[2].active = true;
      this.$route.meta.breadcrumb[2].text = `AV / IT`;
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    clicked(item) {
      // console.log(item);
      // const { sf_opportunity_id } = item;
      // this.$router.push({
      //   path: `/quote.avit_spec_quote/${sf_opportunity_id}`,
      // });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    initFilters() {
      if (this.quote.base_spec_version < 3.3) {
        this.avitItems = this.quote.avit_spec_quote;
      } else if (this.quote.av_toggle && this.quote.it_toggle) {
        this.avitItems = this.quote.avit_spec_quote;
      } else if (this.quote.av_toggle) {
        this.avitItems = this.quote.avit_spec_quote.filter(
          (av) => av.subcategory == "AV"
        );
      } else if (this.quote.it_toggle) {
        this.avitItems = this.quote.avit_spec_quote.filter(
          (av) => av.subcategory == "IT"
        );
      }

      // create new property for custom filter usage
      // if (this.avitItems.length > 0) {
      //   this.avitItems.map((f) => {
      //     f.vendor = f.element.vendor;
      //   });
      // }
      // * override manufacturer if there is spec swaps to apply dynamic filters
      if (this.avitItems.length > 0) {
        this.avitItems.map((f) => {
          f.vendor = !f.specification_swap
            ? f.element.vendor
            : f.specification_swap.manufacturer;
        });
      }
      // using total rows for filteres avit items
      this.totalRows = this.avitItems;
      let col = "";
      for (col in this.filters) {
        this.filters[col] = this.totalRows
          .map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      this.activeFilters = Object.assign({}, this.filters);
      // init ffe items
      this.ASUItems = this.quote.options.filter((o) => o.budget_group == "AV/IT");
      // init CO items
      let coApproved = this.change_orders.filter((c) => c.status == "Approved");
      if (coApproved.length > 0) {
        coApproved.map((co) => {
          if (co.change_order_lines.length > 0) {
            co.change_order_lines.map((l) => {
              if (l.budget_group == "AV/IT") {
                this.COItems.push(l);
              }
            });
          }
        });
      }
      this.CAItems = this.quote.allowances.filter((o) => o.budget_group == "AV/IT");
      //  console.log(this.COItems);
      col = "";
      for (col in this.ASUfilters) {
        this.ASUfilters[col] = this.ASUItems.map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      // Init CO filters
      let col3 = "";
      for (col3 in this.COfilters) {
        this.COfilters[col] = this.COItems.map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      //init CA filters
      let col4 = "";
      for (col4 in this.CAfilters) {
        this.CAfilters[col] = this.CAItems.map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      this.ASUactiveFilters = Object.assign({}, this.ASUfilters);
      this.COactiveFilters = Object.assign({}, this.COfilters);
      this.CAactiveFilters = Object.assign({}, this.CAfilters);
    },

    toggleAll(col) {
      this.activeFilters[col] = this.quote.avit_spec_quote
        .map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    isFilterActive(name, value) {
      return this.activeFilters[name] && this.activeFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },

    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      if (key == "total_price_sqft") {
        return this.filteredItems.reduce(
          (a, b) =>
            a +
            (!b.specification_swap
              ? b.total_price / this.quote.sqft || 0
              : (b.specification_swap.total_price * 1 +
                  b.specification_swap.total_delivery_install * 1) /
                this.quote.sqft),
          0
        );
      } else if (key == "total_price") {
        return this.filteredItems.reduce(
          (a, b) =>
            a +
            ((!b.specification_swap
              ? b.total_price
              : b.specification_swap.total_price * 1 +
                b.specification_swap.total_delivery_install * 1) || 0),
          0
        );
      } else if (key == "total_supply_price") {
        return this.filteredItems.reduce(
          (a, b) =>
            a +
            ((!b.specification_swap
              ? b.supply_price * b.qty
              : b.specification_swap.total_price * 1) || 0),
          0
        );
      } else if (key == "total_cost") {
        return this.filteredItems.reduce(
          (a, b) =>
            a +
            ((!b.specification_swap
              ? b.total_cost
              : b.specification_swap.total_price * 1 +
                b.specification_swap.total_delivery_install * 1) || 0),
          0
        );
      } else if (key == "total_delivery_install") {
        return this.filteredItems.reduce(
          (a, b) =>
            a +
            ((!b.specification_swap
              ? b.total_delivery_install
              : b.specification_swap.total_delivery_install * 1) || 0),
          0
        );
      } else if (key == "cost_per_sqft") {
        return this.filteredItems.reduce(
          (a, b) =>
            a +
            ((!b.specification_swap
              ? b.cost_per_sqft
              : (b.specification_swap.total_price * 1 +
                  b.specification_swap.total_delivery_install * 1) /
                this.quote.sqft) || 0),
          0
        );
      } else {
        return this.filteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      }
      // return 0;
    },

    currentItems: function (value) {
      this.filteredItems = value;
    },
    // ff&e functions
    markup(supply, cost) {
      return supply - cost;
    },
    ASUcurrentItems: function (value) {
      this.ASUfilteredItems = value;
    },
    // display total columns
    totalColumnShow(col) {
      let isSelected = false;
      if (this.displayColumnsCheck.length > 0) {
        let res = this.displayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    //* ASU methods
    ASUtoggleAll(col) {
      this.ASUactiveFilters[col] = this.ASUItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    ASUtotalColumnShow(col) {
      let isSelected = false;
      if (this.ASUdisplayColumnsCheck.length > 0) {
        let res = this.ASUdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    ASUsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.ASUfilteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      // return 0;
    },   
    ASUclearAll(col) {
      this.ASUactiveFilters[col] = [];
    },
    ASUisFilterActive(name, value) {
      return this.ASUactiveFilters[name] && this.ASUactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    // ASUtoggleAll(col) {
    //   this.activeFilters[col] = this.quote.avit_spec_quote
    //     .map((value) => value[col])
    //     .filter((value, index, _arr) => _arr.indexOf(value) == index)
    //     .sort();
    // },
    //* CO methods
    COtoggleAll(col) {
      this.COactiveFilters[col] = this.COItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    COtotalColumnShow(col) {
      let isSelected = false;
      if (this.COdisplayColumnsCheck.length > 0) {
        let res = this.COdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    COsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.COfilteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      // return 0;
    },
    COclearAll(col) {
      this.COactiveFilters[col] = [];
    },
    COisFilterActive(name, value) {
      return this.COactiveFilters[name] && this.COactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    // ASUtoggleAll(col) {
    //   this.activeFilters[col] = this.quote.avit_spec_quote
    //     .map((value) => value[col])
    //     .filter((value, index, _arr) => _arr.indexOf(value) == index)
    //     .sort();
    // },
    COcurrentItems: function (value) {
      this.COfilteredItems = value;
    },
    // *CA methods
    //* CO methods
    CAtoggleAll(col) {
      this.CAactiveFilters[col] = this.CAItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    CAtotalColumnShow(col) {
      let isSelected = false;
      if (this.CAdisplayColumnsCheck.length > 0) {
        let res = this.CAdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    CAsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.CAfilteredItems.reduce((a, b) => a + (Number(b[key]) || 0), 0);
      // return 0;
    },
    CAclearAll(col) {
      this.CAactiveFilters[col] = [];
    },
    CAisFilterActive(name, value) {
      return this.CAactiveFilters[name] && this.CAactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    // ASUtoggleAll(col) {
    //   this.activeFilters[col] = this.quote.ffe_spec_quote
    //     .map((value) => value[col])
    //     .filter((value, index, _arr) => _arr.indexOf(value) == index)
    //     .sort();
    // },
    CAcurrentItems: function (value) {
      this.CAfilteredItems = value;
    },
    // * select all methods
    AllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = ["name"];
        if (this.fields.length > 0) {
          this.fields.map((f) => {
            this.displayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.displayColumnsCheck = ["name"];
      }
    },
    ASUtoggleAllDrop(checked) {
      if (checked) {
        this.ASUdisplayColumnsCheck = ["name"];
        if (this.ASUfields.length > 0) {
          this.ASUfields.map((f) => {
            this.ASUdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.ASUdisplayColumnsCheck = ["name"];
      }
    },
    COtoggleAllDrop(checked) {
      if (checked) {
        this.COdisplayColumnsCheck = ["name"];
        if (this.COfields.length > 0) {
          this.COfields.map((f) => {
            this.COdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.COdisplayColumnsCheck = ["name"];
      }
    },

    CAtoggleAllDrop(checked) {
      if (checked) {
        this.CAdisplayColumnsCheck = ["name"];
        if (this.CAfields.length > 0) {
          this.CAfields.map((f) => {
            this.CAdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.CAdisplayColumnsCheck = ["name"];
      }
    },
    // * reset cols
    resetCols(table) {
      if (table == "base") {
        this.displayColumnsCheck = [
          "name",
          "element",
          "unit_cost",
          // "vendor",
          "subcategory",
          "qty",
          "total_cost",
          "cost_per_sqft",
          "total_delivery_install",
        ];
      } else if (table == "asu") {
        this.ASUdisplayColumnsCheck = [
          "name",
          "internal_note",
          "unit_cost",
          "total_cost",
          "cost_per_sqft",
        ];
      } else if (table == "cash") {
        this.CAdisplayColumnsCheck = [
          "name",
          "internal_note",
          "unit_cost",
          "total_cost",
          "cost_per_sqft",
        ];
      } else {
        this.COdisplayColumnsCheck = ["name", "internal_note", "quantity", "total_cost"];
      }
    },
    clicked(item) {
      // console.log(item);
      // const { sf_opportunity_id } = item;
      // this.$router.push({
      //   path: `/quote.ffe_spec_quote/${sf_opportunity_id}`,
      // });
    },
    searchUniformatCodeName(code) {
      let codeName = "";
      this.uniformat["AVIT"].map((u) => {
        if (u.code == code) {
          codeName = u.code_name;
        }
      });
      return codeName;
    },
  },
};
</script>

<style lang="scss" scoped>
.title_divider {
  border: none;
  border-left: 1px solid rgba(31, 41, 47, 0.05);
  height: auto;
  width: 1px;
  margin-right: 25px;
}
.card_title {
  color: #6e6b7b;
  margin-bottom: 4px;
  font-size: 14px;
}
</style>
<style scoped scoped>
.v-data-table >>> .v-data-table-header {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
}
</style>
